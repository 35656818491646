.form > button {
    background-color: white;
    color: var(--main-background-color);
    padding: .6em 1em;
    border: 2px solid var(--main-background-color);
    border-radius: 5px;
    font-size: 1rem;
}

.form > button:hover {
    cursor: pointer;
    background-color: var(--main-background-color);
    color: white;
}

.form-field {
    box-sizing: border-box;
    display: flex;
    position: relative;
    color: black;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.form-field > input[type=text]:focus {
    border: 3px solid #555;
}


.form-field > label {
    font-weight: bold;
    color: var(--main-background-color);
    position: absolute;
    font-size: .825rem;
    margin-top: -4px;
    margin-left: 0.5rem;
    background: linear-gradient(white 8px, transparent 0, transparent);
    display: inline-block;
    padding: 0 0.3rem;
    z-index: 1;
    line-height: 1em;
    white-space: nowrap;
}

.form-field > input:not([type=checkbox]):not([type=radio]),
.form-field > select,
.form-field > textarea {
    width: 100%;
    padding: 0.7rem 0.5rem 0.5rem;
    font-family: Open Sans, sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-field > button,
.form-field > textarea,
.form-field > input:not([type=checkbox]):not([type=radio]), select {
    border: 1px solid gray;
    border-radius: 4px;
    color: inherit;
    background: white;
    font-size: .85rem;
}

.form-field > textarea {
    min-height: 150px;
}

.form-field > input:disabled {
    color: gray !important;
}