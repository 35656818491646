:root {
    --main-title-color: #ffffff;
    --main-text-color: #ffffff;
    --main-background-color: #4066b0;
    --main-max-width: 1100px;
}

.content{
    display: inline-block;
    overflow: hidden;
    width: 100%;
}

h1 {
    color: var(--main-background-color);
    text-align: center;
    font-size: 26px;
}

p {
    text-align: center;
}

.f2 > div {
    width: calc(50% - ( 0.66666666666667 * 30px ) );
    padding: 5px;
    margin-bottom: 20px
}

div.center_vertical {
    margin-top: auto;margin-bottom: auto;
}

.f3 > div {
    width: calc(33.3333% - ( 0.66666666666667 * 30px ) );
    padding: 5px;
    margin-bottom: 20px
}

.f4 > div {
    width: calc(25% - ( 0.66666666666667 * 30px ) );
    padding: 5px;
    margin-bottom: 20px
}

div.f4 > div > a > img {
    height: unset !important;
    width: 100% !important;
}

.f7 > div {
    width: calc(14.2857% - ( 0.85714285714286 * 30px ))
}

.div_center {
    margin-left: 50%;
    translate: -50%;
}

.button_contact {
    display: block;
    background: #4066b0 url(../resources/knop_background.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 108px 58px;
    font-size: 16px;
    height: 50px;
    max-width: 210px;
    margin-top: 15px;
    line-height: 50px;
    text-align: center;
    padding: 0 40px 4px 40px;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    transition: all 0s ease;
}

.button_contact:hover{
    background: #ef7f01 url(../resources/knop_background_oranje.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100px 50px;
    color: #fff;
}

.panel-row {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: var(--main-background-color);
    min-height: 50px;
}

.panel-row > div {
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: var(--main-max-width);
    width: 96%;
    margin: 0 auto 0 auto;
}

.panel-row-flex {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.panel-row-flex > div {
    text-align: center;
}

.panel-row-flex > div > img {
    width: 100%;
}

.panel-row-flex > div > strong {
    color: white;
    width: 100%;
    text-align: center;
}

.black > div > strong {
    color: black
}

.container {
    width: 100%;
}

.container > div {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 96%;
    max-width: var(--main-max-width);
    margin: 0 auto 0 auto;
}

.container_blue {
    background-color: #e6f1f8;
}

.container_white {
    background-color: white;
}

table.images {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding-bottom: 30px;
}

table.images tr td img {
    max-width: 54px;
    height: auto;
}

table.brands tr td img {
    max-width: unset;
}

table.images2 {
    max-width: unset;
}

table.images2 tr td img {
    max-width: 100%;
}

div.contact {
    text-align: left;
    padding: unset;
}

div.contact > label,
div.contact > textarea,
div.contact > input {
    width: -webkit-fill-available;
}

div.items > div > a {
    text-decoration: none;
}

div.items > div > a > img {
    height: 230px;
    width: unset;
}

sup {
    color: red;
    font-weight: bold;
}

a.product > p {
    color: black;
    text-align: left;
}

a.product > p > ul {
    font-size: 14px;
}

a.product > h1 {
    min-height: 61px;
}

a.product:hover {
    opacity: 0.6;
}

.description > h1,
.description > p {
    text-align: left;
}

table.specTable {
    border-collapse: collapse;
    text-align: left;
    width: 100%;
}

/*table.specTable > tbody > tr:nth-child(odd) {*/
/*    background-color: #d1f3ff;*/
/*}*/

table.specTable > tbody > tr:nth-child(even) {
    background-color: #f7f7f7;
}

@media only screen and (max-width: 400px) {
    .panel-row-flex {
        flex-direction: column;
    }

    .panel-row-flex > div {
        width: 96%;
    }

    table.brands {
        max-width: unset;
    }

    table.brands img {
        width: 100%;
    }

    table.images2 > tbody > tr > td {
        width: 100%;
    }
}