.divSlideShow > img {
    vertical-align: middle;
}

/* Position the image divSlideShow (needed to position the left and right arrows) */
.divSlideShow {
    position: relative;
}

/* Hide the images by default */
.divSlides {
    height: 570px;
    max-height: 570px;
    display: none;
}

.divSlides > img {
    width: auto;
    max-width: 100%;
    max-height: 550px;
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
    cursor: pointer;
}

.aPrev {
    left: 0;
}

/* Next & previous buttons */
.aPrev,
.aNext {
    color: #416fb3;
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}

/* Position the "next button" to the right */
.aNext {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.aPrev:hover,
.aNext:hover {
    background-color: rgba(159, 159, 159, 0.8);
}

/* Number text (1/3 etc) */
.divNumbers {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* divSlideShow for image text */
.caption-divSlideShow {
    text-align: center;
    background-color: #222;
    padding: 2px 16px;
    color: white;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Six columns side by side */
.column {
    float: left;
    width: 16.66%;
}

/* Add a transparency effect for thumnbail images */
.imgDemo {
    opacity: 0.6;
}

.active,
.imgDemo:hover {
    opacity: 1;
}